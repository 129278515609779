var exports = {};
// Maps for number <-> hex string conversion
var _byteToHex = [];
var _hexToByte = {};

for (var i = 0; i < 256; i++) {
  _byteToHex[i] = (i + 256).toString(16).substr(1);
  _hexToByte[_byteToHex[i]] = i;
} // **`parse()` - Parse a UUID into it's component bytes**


function parse(s, buf, offset) {
  var i = buf && offset || 0;
  var ii = 0;
  buf = buf || [];
  s.toLowerCase().replace(/[0-9a-f]{2}/g, function (oct) {
    if (ii < 16) {
      // Don't overflow!
      buf[i + ii++] = _hexToByte[oct];
    }
  }); // Zero out remaining bytes if string was short

  while (ii < 16) {
    buf[i + ii++] = 0;
  }

  return buf;
} // **`unparse()` - Convert UUID byte array (ala parse()) into a string**


function unparse(buf, offset) {
  var i = offset || 0;
  var bth = _byteToHex;
  return bth[buf[i++]] + bth[buf[i++]] + bth[buf[i++]] + bth[buf[i++]] + "-" + bth[buf[i++]] + bth[buf[i++]] + "-" + bth[buf[i++]] + bth[buf[i++]] + "-" + bth[buf[i++]] + bth[buf[i++]] + "-" + bth[buf[i++]] + bth[buf[i++]] + bth[buf[i++]] + bth[buf[i++]] + bth[buf[i++]] + bth[buf[i++]];
}

exports = {
  parse: parse,
  unparse: unparse
};
export default exports;
const _parse = exports.parse,
      _unparse = exports.unparse;
export { _parse as parse, _unparse as unparse };